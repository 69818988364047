<div class="delete-account otp-authorization">
     <h1 class="header-title">enter authorization code</h1>
    <!-- <div class="header-line"></div>  -->
    <!-- <button class="confirm-btn next-btn mb-50" mat-button> send code for confirmation</button> -->
    <div class="text-center">
        <ng-otp-input #ngOtpInput (onInputChange)="onOtpChange($event)" [config]="config"></ng-otp-input>
    </div>
    <div class="showCodeOtp p-3 mb-3">
        <button id="showcode" class="show-code auth-code" (click)="showCode()">
            <mat-icon matSuffix class="eye-icon">visibility</mat-icon>
            show code
        </button>
        <button class="resend-otp auth-resend" (click)="resendOtp()">resend otp</button>
    </div>
    <!-- <div class="full-width pb-30 pt-30">
        <div class="custom-checkbox">
        <mat-checkbox class="i-agree text-white"><a>I'm sure i want to delete my account</a>
        </mat-checkbox>
        </div>
    </div> -->
    <button class="login-btn back-btn" mat-button (click)="onSubmit()"> submit</button>
</div>

