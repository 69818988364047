import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { LoginService } from '../core/services/login.service';
import { Router } from '@angular/router';
import { HttpClient, HttpParams } from '@angular/common/http';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  loginForm = true;
  otpContent = false;
  dialCode: any;
  loginContent: boolean = true;
  emailAddress: any;

  otpParams:any =  {
    "adminId":"",
    "loginPin":"",
    "loginUsing": "phone",
     "deviceId":"web"
  }
  topHeaderForm = this.fb.group({
    phone:[null, Validators.required],
    // email:[null,Validators.required],
    password: [null,Validators.required]
  });
  loginResponse: any;
  constructor(
    private loginService : LoginService,
    private router : Router,
    private http: HttpClient,
    private fb: FormBuilder,
  ) {  }

  ngOnInit(): void {
  }

  CountryChangedEvent(event){
    this.dialCode =  event.dialCode;
  }
  onOTPClose(){
    this.otpContent = false;
    this.loginContent = true;
  }

  onOTP(loginPin){
    let adminId = this.loginResponse.adminId.toString();
    let params = {
      'adminId': adminId,
      'loginPin':loginPin,
      'loginUsing': this.otpParams.loginUsing,
      'deviceId': this.otpParams.deviceId
    }
   this.loginService.validateOtp(params).subscribe(
     (res)=>{
        console.log(res);
        this.router.navigate(['/user-list']);
     },
     (err)=> {
       console.log(err);
     }
   )
  }
  onLogin(){
    this.topHeaderForm.markAllAsTouched();
    let LoginForm = this.topHeaderForm.value;
    if((LoginForm.email || LoginForm.phone )){
      let phone = this.topHeaderForm.get('phone').value;
      // let userName = this.topHeaderForm.get('email').value;
      let password = this.topHeaderForm.get('password').value;
      // if(phone) {
      //   var phoneComponents = {
      //     number: phone.substring(this.dialCode.length + 1, phone.length)
      //   };
      // }
      // let params =  new HttpParams()
      // .set('phoneNumber',phoneComponents ? phoneComponents.number : '')
      // .set('countryCode',this.dialCode)
      // .set('emailId', email);
      let cleanedPhoneNumber = phone.replace("+", "");

      let params =  new HttpParams()
      .set('username', cleanedPhoneNumber)
      .set('password', password)
      .set('grant_type','password')
      .set('client_id', 'dryo-super-admin-api');

      this.loginService.login(params)
        .subscribe(
          (data) => {
            if (data) {
              this.loginResponse = data;
            //  this.otpContent = true;
             // this.loginContent = false;
             this.router.navigate(['/user-list']);
            }
          },
          (error) => {
      });
    }
  }
}
