import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxMatIntlTelInputModule } from 'ngx-mat-intl-tel-input';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { LoginComponent } from './login/login.component';
import { PrimengModule } from './primeng.module';
import { NgOtpInputModule } from 'ng-otp-input';
import { MaterialModule } from './material.module';
import { OtpAuthorizationComponent } from './login/otp-authorization/otp-authorization.component';
import { NotifyDialogComponent } from './core/notify-dialog/notify-dialog.component';
import { ConfirmDeleteDialogComponent } from './core/confirm-dialog/confirm-delete-dialog.component';
import { BasicAuthInterceptor } from './core/Auth/Auth.interceptor';
import { ToastModule } from 'primeng/toast';
import { MessageService } from 'primeng/api';
@NgModule({
  declarations: [
    AppComponent,
    NotifyDialogComponent,
    LoginComponent,
    OtpAuthorizationComponent,
    ConfirmDeleteDialogComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgxMatIntlTelInputModule,
    MaterialModule,
    PrimengModule,
    NgOtpInputModule,
    ToastModule,
  ],
  providers: [
    {provide: LocationStrategy, useClass: HashLocationStrategy},
    { provide: HTTP_INTERCEPTORS, useClass: BasicAuthInterceptor, multi: true },
    MessageService
  ],
  bootstrap: [AppComponent],
  entryComponents:[ConfirmDeleteDialogComponent]
})
export class AppModule { }
