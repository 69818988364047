import { Component, OnInit, Inject, HostListener, ElementRef } from '@angular/core';
import {  MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ConfirmDialogService } from './confirmdialog.service';

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-delete.component.html'
})
export class ConfirmDeleteDialogComponent implements OnInit {


  constructor(@Inject(MAT_DIALOG_DATA) public data:any,
              public dialogRef:MatDialogRef<ConfirmDeleteDialogComponent>,
              private elementRef: ElementRef) { }


  ngOnInit(): void {
  }

  closeDialog() {
    this.dialogRef.close(false);
  }  
  
}
