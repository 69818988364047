<div>
    <div class = "content-container">
        <mat-icon id="close-icon" (click)="closeDialog()">close</mat-icon>
        <span class="content-span">{{data.message}}</span>
    </div>
    <div class="btn-container button-group">
        <div class="display-inline">
            <button  type="button" class="btn-fill btn-no" [mat-dialog-close]="false">no</button>
        </div>
        <div class="display-inline">
            <button type="button" class="btn-fill btn-yes" [mat-dialog-close]="true" >yes</button>
        </div>
    </div>
</div>

