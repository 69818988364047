import { HttpParams } from '@angular/common/http';
import { Component, OnInit, Output,EventEmitter  } from '@angular/core';
import { Data, Router } from '@angular/router';
import { LoginService } from 'src/app/core/services/login.service';
// import { DataService } from 'src/app/core/services/data.service';

@Component({
  selector: 'app-otp-authorization',
  templateUrl: './otp-authorization.component.html',
  styleUrls: ['./otp-authorization.component.scss']
})
export class OtpAuthorizationComponent implements OnInit {
  @Output() otpEvent: any = new EventEmitter<any>();
  otp: any;
  config = {
    allowNumbersOnly: true,
    length: 4,
    isPasswordInput: false,
    disableAutoFocus: true,
    placeholder: '',
    value: '6565',
    inputStyles: {
      'width': '70px',
      'height': '89px',
      'border': '2px solid #fff',
      'background': 'transparent',
      'color': '#091a38',
      'border-radius': '0',
      'margin-right': '30px',
      'font-size': '60px',
      'font': 'normal normal 500 18px Avenir Next !important'
    },
  }
  constructor(
    private loginService: LoginService,
    private router: Router
  ) { }

  ngOnInit(): void {
  }
  onOtpChange(otp) {
    this.otp = otp;
  }
  showCode() {
    let showcode: any = document.getElementById("showcode").childNodes[0];
    const showHTML = showcode.innerHTML === 'visibility_off' ? 'visibility' : 'visibility_off';
    showcode.innerHTML = showHTML;

    let childrens: any = document.getElementsByTagName('ng-otp-input')[0].childNodes[0].childNodes;
    for (let child of childrens) {
      const type = child.type === 'password' ? 'text' : 'password';
      child.type = type;
    }
  }
  resendOtp() { }

  onSubmit() {
    this.otpEvent.emit(this.otp);
  }
}
