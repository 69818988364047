<div class="row login-page">
    <!--  -->
    <div class="col-md-12 text-center">
        <div class="main-circle">
            <div class="close-icon" *ngIf="otpContent">
                <button mat-icon-button class="close-button" (click)="onOTPClose()">
                    <mat-icon class="icon" color="">close</mat-icon>
                </button>
            </div>
            <div class="row" [ngClass]="{'display-none': !loginContent}">
                <form #f="ngForm" [formGroup]="topHeaderForm " class="col-sm-12 login-form">

                    <mat-form-field color="accent" appearance="standard" class="field-6 mat-country-selection">
                        <ngx-mat-intl-tel-input inputPlaceholder="registered number" [preferredCountries]="['us','in']"
                            [enablePlaceholder]="true" name="phone" (countryChanged)="CountryChangedEvent($event)"
                            formControlName="phone"></ngx-mat-intl-tel-input>
                        <mat-error *ngIf="f.form.controls['phone']?.errors?.required">phone is required</mat-error>
                        <mat-error *ngIf="f.form.controls['phone']?.errors?.validatePhoneNumber">invalid phone number
                        </mat-error>
                    </mat-form-field>

                    <!-- <div class="or">or</div> -->
                    
                    <!-- <mat-form-field color="accent" appearance="standard" class="field-6 email-address">
                        <input matInput formControlName="email" placeholder="email address">
                        <mat-error *ngIf="f.form.controls['email']?.errors?.required">email is required</mat-error>
                        <mat-error *ngIf="f.form.controls['email']?.errors?.email">email must be a valid email address</mat-error>
                      </mat-form-field> -->

                      <mat-form-field color="accent" appearance="standard" class="field-6 email-address">
                        <input matInput type="password" formControlName="password" placeholder="password">
                        <mat-error *ngIf="f.form.controls['password']?.errors?.required">password is required</mat-error>
                      </mat-form-field>
                </form>
                <div class="col-sm-12 login-group">
                    <button (click)="onLogin()" class="login-btn back-btn" mat-button>Login</button>
                </div>
            </div>
            <ng-container *ngIf="otpContent">
                <app-otp-authorization (otpEvent)="onOTP($event)"></app-otp-authorization>
            </ng-container>
        </div>
    </div>
</div>