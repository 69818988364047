import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import {map} from 'rxjs/operators';
import { Router } from '@angular/router';
import jwt_decode from 'jwt-decode';
@Injectable({
  providedIn: 'root'
})
export class LoginService {
  baseURL: string;
  loginURL: string
  constructor(
    private http:HttpClient,
    private router: Router
  ) {
    this.baseURL = environment.baseURL;
    this.loginURL = environment.loginURL;
   }


  login(data) {
    // return this.http.get<any>(this.baseURL + '/loginRequest', {params:data})
    // .pipe(map(user => {
    //   return user;
    // }));
    return this.http.post<any>(this.loginURL,data)
    .pipe(map(user => {
      this.setUser(user);
      return user;
    }));
  }

  setUser(data) {
    // const helper = new JwtHelperService();
    // const decoded= helper.decodeToken(data.access_token);
    // console.log(decoded);
    let userInfo = this.getDecodedAccessToken(data.access_token);
    // sessionStorage.setItem('userName',data.access_token);
    console.log(userInfo);
    sessionStorage.setItem('userName',JSON.stringify(userInfo.given_name));
    sessionStorage.setItem('token',data.access_token);
  }

  getDecodedAccessToken(token: string): any {
    try {
      return jwt_decode(token);
    } catch(Error) {
      return null;
    }
  }

  validateOtp(body){
    return this.http.post<any>(this.baseURL + '/login', body)
    .pipe(map(user => {
      this.setUser(user);
      return user;
    }));
  }

  getVendorUsersList() {
    return this.http.get<any>(this.baseURL + '/vendors');
  }


  getUsersList(page, pageSize) {
    return this.http.get<any>(this.baseURL + `/users?pageNo=${page}&pageSize=${pageSize}`);
   // return this.http.get('/assets/mock/userlist.json');
  }

  deleteUser(id) {
    return this.http.delete(this.baseURL + '/user/' + id);
  }

  vendorStatusChange(vendorId, statusId) {
    let params = {
        "vendorId" : vendorId,
        "statusId" : parseInt(statusId)
    }
    return this.http.put(this.baseURL + '/vendorStatus/', params);
  }

  public isLoggedIn(): boolean {
    let token: any = sessionStorage.getItem("token");
    if (token) {
      return true;
    } else {
      this.router.navigate(['/login']);
      return false;
    }
}
}
