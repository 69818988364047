import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {TableModule} from 'primeng/table';
import {ButtonModule} from 'primeng/button';
import {InputTextModule} from 'primeng/inputtext';

const primeModules = [
  TableModule,
  ButtonModule,
  InputTextModule
]


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    ...primeModules
  ],
  exports:[...primeModules]
})
export class PrimengModule { }
